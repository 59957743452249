"use client";
import {Fragment, useState, useEffect} from "react";
import {usePathname} from "next/navigation";
import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Stack,
  useColorModeValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import {AiOutlineClose} from "@onemind-services-llc/react-icons-ng/ai";
import {GiHamburgerMenu} from "@onemind-services-llc/react-icons-ng/gi";
import {FaUserCircle} from "@onemind-services-llc/react-icons-ng/fa";
import {useDashboardLoginURL} from "@/hooks/useDashboardLoginURL";
import {useIsAuthenticated} from "@/hooks/useIsAuthenticated";
import Logo from "../shared/Logo";
import {DesktopNav} from "./DesktopNav";
import {MobileNav} from "./MobileNav";
// import SaleBar from "./saleBar";

const LOGOUT_URL = "/api/auth/logout";

const Navbar = () => {
  const {isOpen, onToggle} = useDisclosure();
  const {isAuthenticated, isLoading} = useIsAuthenticated();
  const pathname = usePathname();
  const [domain, SetDomain] = useState("");
  const dashboardLoginURL = useDashboardLoginURL();

  useEffect(() => {
    SetDomain(window.location.origin);
  }, []);

  const loginURL = `/api/auth/login?returnTo=${pathname}`;
  const registerURL = `${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL}/auth/register?login_url=${encodeURIComponent(`${domain}${loginURL}`)}`;

  return (
    <Box
      position="fixed"
      top={0}
      width={"100%"}
      zIndex={10}
      borderBottom={1}
      bgColor={"white"}
      borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.900")}
    >
      {/* <SaleBar /> */}
      <Flex
        width={"100%"}
        maxW={"8xl"}
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        align={"center"}
        justifyContent="center"
        paddingY={"min(2%, 0.5rem)"}
        paddingX={8}
        marginX={"auto"}
      >
        <Flex
          flex={{base: 1, md: "auto"}}
          ml={{base: -2}}
          display={{base: "flex", md: "none"}}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? (
                <Icon as={AiOutlineClose} w={3} h={3} />
              ) : (
                <Icon as={GiHamburgerMenu} w={5} h={5} />
              )
            }
            variant={"ghost"}
            title={"Hamburger"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{base: 1}} justify={{base: "center", md: "start"}} mr={{base: 10}}>
          <Box data-cy="brand-header-logo">
            <Logo />
          </Box>
          <Flex display={{base: "none", md: "flex"}} ml={8}>
            <DesktopNav />
          </Flex>
        </Flex>

        {!isLoading && (
          <Fragment>
            <Stack
              display={{base: "none", lg: "flex"}}
              flex={{base: 1, md: 0}}
              justify={"flex-end"}
              direction={"row"}
              spacing={2}
            >
              {isAuthenticated ? (
                <Fragment>
                  <Button
                    as={"a"}
                    fontSize={"19px"}
                    fontWeight={500}
                    href={dashboardLoginURL}
                    color={"gray.600"}
                    width={"min-content"}
                    _hover={{
                      bg: "#007FFF",
                      color: "white",
                    }}
                    target="_blank"
                  >
                    My Account
                  </Button>
                  <Button
                    as={"a"}
                    fontSize={"19px"}
                    fontWeight={500}
                    href={LOGOUT_URL}
                    bg={"white"}
                    borderWidth={"1px"}
                    borderColor={"gray.600"}
                    color={"gray.600"}
                    _hover={{
                      bg: "#007FFF",
                      color: "white",
                    }}
                  >
                    Sign Out
                  </Button>
                </Fragment>
              ) : (
                <Fragment>
                  <Button
                    as={"a"}
                    fontSize={"19px"}
                    fontWeight={500}
                    href={registerURL}
                    color={"gray.600"}
                    width={"min-content"}
                    bg={"white"}
                    _hover={{color: "#4299e1"}}
                  >
                    Register
                  </Button>
                  <Button
                    as={"a"}
                    fontSize={"19px"}
                    fontWeight={500}
                    href={loginURL}
                    bg={"white"}
                    borderWidth={"1px"}
                    borderColor={"gray.600"}
                    color={"gray.600"}
                    _hover={{
                      bg: "#007FFF",
                      color: "white",
                    }}
                  >
                    Sign In
                  </Button>
                </Fragment>
              )}
            </Stack>
            <Box display={{lg: "none"}} mr={-2}>
              <Menu>
                <MenuButton as={Button} bg={"white"} color={"gray.600"}>
                  <Icon as={FaUserCircle} w={6} h={7} />
                </MenuButton>
                <MenuList>
                  {isAuthenticated ? (
                    <Fragment>
                      <MenuItem
                        as={"a"}
                        href={dashboardLoginURL}
                        fontSize={"16px"}
                        fontWeight={600}
                        _hover={{bg: "blue.50"}}
                      >
                        My Account
                      </MenuItem>
                      <MenuItem
                        as={"a"}
                        href={LOGOUT_URL}
                        fontSize={"16px"}
                        fontWeight={600}
                        _hover={{bg: "blue.50"}}
                      >
                        Sign Out
                      </MenuItem>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <MenuItem
                        as={"a"}
                        href={registerURL}
                        fontSize={"16px"}
                        fontWeight={600}
                        _hover={{bg: "blue.50"}}
                      >
                        Register
                      </MenuItem>
                      <MenuItem
                        as={"a"}
                        href={loginURL}
                        fontSize={"16px"}
                        fontWeight={600}
                        _hover={{bg: "blue.50"}}
                      >
                        Sign In
                      </MenuItem>
                    </Fragment>
                  )}
                </MenuList>
              </Menu>
            </Box>
          </Fragment>
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
};

export default Navbar;
